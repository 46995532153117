<template>
  <EmailLoader>
    <p>Great news! Your monthly report is ready!</p>

    <p>You can view your report by clicking the button below.</p>

    <div class="text-center">
      <b-button variant="primary">
        Monthly Report
      </b-button>
    </div>
  </EmailLoader>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import EmailLoader from '../setup/loader.vue'

export default {
  components: {
    BButton,
    EmailLoader,
  },
}
</script>
